@import '../../index.scss';

* {
  font-family: $fontFamily;
  color: #f8f1f1;
  font-size: 20px;
  line-height: 30px;

  @media (max-width: 1100px) {
    font-size: 18px;
  }

  @media (max-width: 550px) {
    font-size: 16px;
    text-align: center;
  }
}

.landing {
  &__background-photo {
    background: no-repeat top/100% url('../../images/sssssss.jpg');

    @media (max-width: 1250px) {
      background: no-repeat top/125% url('../../images/sssssss.jpg');
    }

    @media (max-width: 999px) {
      background: no-repeat top/250% url('../../images/sssssss.jpg');
    }

    @media (max-width: 580px) {
      background: no-repeat top/300% url('../../images/sssssss.jpg');
    }

    @media (max-width: 500px) {
      background: no-repeat top/450% url('../../images/sssssss.jpg');
    }

    @media (max-width: 400px) {
      background: no-repeat top/500% url('../../images/sssssss.jpg');
    }
  }

  &__dark-bg {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__fully-dark-bg {
    background: #100c08;
    padding-bottom: 80px;
  }

  &__top-button {
    font-family: $fontFamily;
    font-size: 24px;
    line-height: 27px;
    letter-spacing: 0.035em;
    text-transform: uppercase;
    color: $colorText2;
    background: #151515;
    height: 60px;
    width: 100%;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    border: none;

    &:hover {
      background-color: rgba(0, 0, 0, 1);
    }
  }

  &__take-my-money {
    margin-top: -8px;
    position: relative;

    .green-line {
      position: absolute;
      top: 50px;
      width: 100%;

      @media (max-width: 999px) {
        top: 25px;
      }
    }

    &-wrapper {
      display: flex;
      gap: 100px;
      padding-top: 150px;
      margin-bottom: 100px;

      @media (max-width: 1440px) {
        gap: 60px;
        padding-top: 140px;
        margin-bottom: 60px;
      }

      @media (max-width: 999px) {
        padding-top: 90px;
      }

      @media (max-width: 999px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .take-my-money-img {
        position: relative;

        @media (max-width: 999px) {
          max-width: 80%;
        }
      }

      .take-my-money-bg {
        position: absolute;
        width: 300px;
        height: 380px;
        opacity: 0.2;
        border: 1px solid #2df30d;
        border-radius: 40px 0px;

        @media (max-width: 999px) {
          top: 8%;
        }

        @media (max-width: 750px) {
          width: 45%;
          height: 45%;
        }

        @media (max-width: 630px) {
          height: 30vh;
        }

        @media (max-width: 530px) {
          height: 250px;
        }

        @media (max-width: 450px) {
          height: 230px;
        }

        @media (max-width: 360px) {
          height: 20vh;
        }
      }
    }
  }

  &__text-1 {
    display: flex;
    padding: 50px 0;
    gap: 100px;

    @media (max-width: 960px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    &-img {
      max-width: 440px;
      width: 100%;
      height: 100%;
    }

    @media (max-width: 1100px) {
      gap: 70px;
    }

    @media (max-width: 650px) {
      gap: 50px;
      padding: 25px 0px;
    }
  }

  &__text-2 {
    display: flex;
    padding: 50px 0;
    gap: 100px;

    &-img {
      height: 100%;
      aspect-ratio: 9/16;
      transform: scale(1.011);
      @media (max-width: 960px) {
        aspect-ratio: 16/9;
        width: 100%;
        height: 300px;
        transform: scale(1.021);
      }
      &-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      &-container {
        border-radius: 35px 0 35px 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @media (max-width: 960px) {
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: 1100px) {
      gap: 70px;
    }

    @media (max-width: 650px) {
      gap: 50px;
      padding: 25px 0px;
    }

    .aim {
      display: block;
      margin-left: auto;
      max-width: 285px;

      @media (max-width: 960px) {
        max-width: 185px;
      }

      @media (max-width: 450px) {
        display: none;
      }
    }

    &-text {
      padding-top: 70px;

      @media (max-width: 450px) {
        padding-top: 0px;
      }
    }
  }

  &__text-3 {
    display: flex;
    padding: 50px 0;
    gap: 100px;

    @media (max-width: 960px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    @media (max-width: 1100px) {
      gap: 70px;
    }

    @media (max-width: 650px) {
      gap: 50px;
      padding: 25px 0px;
    }

    &-text {
      display: flex;
      align-items: center;
      height: 85%;
    }

    &-img {
      max-width: 440px;
      width: 100%;
      height: 100%;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;

    .button {
      margin: 80px 0px;
      background: none;
      font-family: $fontFamily;
      color: $colorText;
      text-transform: uppercase;
      box-shadow: 0px 4px 8px 4px #2fe212;
      border-radius: 40px 0px;
      width: 60%;
      height: 38px;
      font-size: 20px;
      line-height: 23px;
      border: none;
      cursor: pointer;
      transition: all 0.15s ease-in-out;

      @media (max-width: 1350px) {
        margin: 40px 0px 60px;
      }

      @media (max-width: 750px) {
        width: 90%;
      }

      @media (max-width: 550px) {
        font-size: 16px;
      }

      @media (max-width: 450px) {
        height: 50px;
        padding: 0 25px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }

      &-one {
        @media (max-width: 1350px) {
          margin: 40px 0px;
        }

        @media (max-width: 450px) {
          margin: -50px 0 40px !important;
        }
      }

      &-one {
        @media (max-width: 1350px) {
          margin: -20px 0px 60px;
        }
      }
    }
  }

  &__text {
    font-family: $fontFamily;
    color: #f8f1f1;
    font-size: 20px;
    line-height: 30px;
    padding: 20px 0px;

    @media (max-width: 1100px) {
      font-size: 18px;
    }

    @media (max-width: 830px) {
      padding: 15px 0px;
    }

    @media (max-width: 550px) {
      padding: 10px 0px;
      font-size: 16px;
    }

    &-note {
      color: #2df30d;
    }
  }

  &__images-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 350px;

    @media (max-width: 830px) {
      margin-bottom: 150px;
    }

    &-image-2 {
      @media (max-width: 830px) {
        display: none;
      }
    }

    &-bg {
      position: absolute;
      background-color: rgba(9, 65, 0, 0.5);
      height: 265px;
      width: 100%;
      z-index: 1;
    }

    img {
      z-index: 2;

      @media (max-width: 830px) {
        max-width: 440px;
        width: 75%;
        height: 100%;
      }
    }
  }
}

.green-line {
  opacity: 0.2;
  border: 1px solid #2fe212;
}

.green-line-2 {
  opacity: 0.2;
  border: 1px solid #2fe212;
  margin-top: -120px;
}
