.nft {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 220px;
    border: 1px solid rgba(47, 226, 18, 0.5);
    border-radius: 0px 60px;
    padding: 20px;

    &-img {
      display: flex;
      width: 100%;
      height: 100%;
      border-radius: 0px 60px;
      background-color: #151515;
    }

    @media (max-width: 900px) {
      width: 260px;
      height: 260px;
    }

    @media (max-width: 749px) {
      width: 200px;
      height: 200px;
    }

    @media (max-width: 375px) {
      width: 180px;
      height: 180px;
    }
  }

  &__image {
    width: 100%;
    border-radius: 15%;
    object-fit: contain;
  }
}
