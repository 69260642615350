.select-wrapper {
  .select-option {
    width: 300px;
    height: 40px;
    border: 1px solid #2fe212;
    border-radius: 40px 0px;
    letter-spacing: 0.035em;
    color: #ffffff;
    font-size: 20px;
    padding: 5px 25px;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    text-align: left;

    @media (max-width: 870px) {
      width: 250px;
    }
  }
}

.arrow {
  border: solid #2fe212;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  right: 30px;
  top: 10px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
