@import '../../index.scss';

* {
  font-family: $fontFamily;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  gap: 10px;
}
.mint-page {
  &__nav {
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    margin-bottom: 80px;
    background: #151515;
    height: 60px;
    transition: all 0.15s ease-in-out;
    padding: 0 60px;

    @media (max-width: 1150px) {
      flex-direction: column;
      height: 150px;

      p {
        margin-bottom: -5px;
      }

      @media (max-width: 525px) {
        height: 180px;
        padding: 0 30px;
      }
    }

    &-center-button {
      font-size: 24px;
      line-height: 27px;
      letter-spacing: 0.035em;
      text-transform: uppercase;
      color: $colorText2;
      background-color: #151515;
      border: none;
      margin: 0 auto;

      @media (max-width: 1150px) {
        margin: 10px;
      }

      @media (max-width: 600px) {
        font-size: 20px;
      }
    }

    &-connect-wallet {
      width: 300px;
      height: 40px;
      background: #2df30d;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 40px 0px;
      color: black;
      text-transform: uppercase;
      cursor: pointer;
      border: none;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.035em;
      transition: all 0.15s ease-in-out;

      &:hover {
        background: #16a800;
      }

      @media (max-width: 600px) {
        height: 30px;
      }

      @media (max-width: 385px) {
        width: 270px;
      }
    }

    p {
      color: white;
    }
  }

  &__background-photo {
    background: no-repeat top/100% url('../../images/background.png');
    background-attachment: fixed;

    background-attachment: fixed;
    min-height: 100%;

    @media (max-width: 1350px) {
      background-size: 150%;
    }

    @media (max-width: 999px) {
      background-size: 250%;
    }

    @media (max-width: 580px) {
      background-size: 300%;
    }

    @media (max-width: 500px) {
      background-size: 450%;
    }

    @media (max-width: 400px) {
      background-size: 500%;
    }
  }

  &__dark-bg {
    padding-bottom: 50px;
    background-color: rgba(0, 0, 0, 0.8);

    @media (max-width: 700px) {
      padding-bottom: 25px;
    }

    @media (max-width: 500px) {
      padding-bottom: 5px;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;

    .button {
      margin: 100px 0px 80px;
      background: none;
      color: $colorText;
      text-transform: uppercase;
      box-shadow: 0px 4px 8px 4px #2fe212;
      border-radius: 40px 0px;
      width: 60%;
      height: 38px;
      font-size: 20px;
      line-height: 23px;
      border: none;
      cursor: pointer;
      transition: all 0.15s ease-in-out;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__text-wrapper {
    background-color: rgba(9, 65, 0, 0.5);
  }

  &__text {
    color: #f8f1f1;
    font-size: 20px;
    line-height: 30px;
    padding: 30px 170px;
  }

  &__balances {
    width: 100%;

    &-text {
      color: #ffffff;
      font-size: 20px;
      padding: 20px 0;
      margin: 0 auto;
    }

    &-bg {
      background: rgb(7, 7, 7, 0.5);
      width: 100%;
      margin-bottom: 30px;
      flex-direction: column;
      display: flex;
      justify-content: center;
      padding-bottom: 35px;
    }

    &-container {
      width: 1280px;
      height: 102px;
      border: 1px solid rgb(47, 226, 18, 0.4);
      border-radius: 60px 0px;
      margin: auto;
      padding: 31px 0;

      @media (max-width: 1350px) {
        width: 90%;
      }
    }

    &-currency-amount {
      width: 100%;
      height: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgb(0, 0, 0, 0.4);

      @media (max-width: 500px) {
        visibility: hidden;
      }

      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.035em;
        color: white;
      }
    }

    &-text-wrapper {
      display: flex;
      width: 50%;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 700px) {
        width: 100%;
        justify-content: space-around;
      }

      @media (max-width: 500px) {
        width: 100%;
        justify-content: space-around;
        flex-direction: column;
        visibility: initial;
      }
    }
  }

  &__nfts {
    margin: 60px 0;

    @media (max-width: 800px) {
      margin: 30px 0;
    }

    ::-webkit-scrollbar {
      width: 8px;
      height: 3px;
    }
    ::-webkit-scrollbar-track {
      background-color: #646464;
    }
    ::-webkit-scrollbar-track-piece {
      background-color: #000;
    }
    ::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: #666;
      border-radius: 3px;
    }
    ::-webkit-scrollbar-corner {
      background-color: #646464;
    }
    ::-webkit-resizer {
      background-color: #666;
    }

    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      justify-items: center;
      grid-template-rows: auto auto;
      grid-auto-rows: 0px;
      grid-gap: 20px 0px;
      width: 100%;
      overflow: hidden;
      overflow-y: auto;

      @media (max-width: 1350px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @media (max-width: 1145px) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @media (max-width: 900px) {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: unset;
        max-height: 500px;
      }

      @media (max-width: 629px) {
        grid-template-columns: 1fr;
      }
    }
  }

  &__input-wrapper {
    display: flex;
    justify-content: center;
    gap: 125px;
    margin-top: 30px;

    @media (max-width: 870px) {
      gap: 75px;
    }

    @media (max-width: 700px) {
      flex-direction: column;
      gap: 20px;
      margin-top: 15px;
    }
  }

  &__select,
  &__input,
  &__input-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  &__input {
    input {
      width: 300px;
      background: transparent;
      height: 40px;
      border: 1px solid #2fe212;
      border-radius: 40px 0px;
      letter-spacing: 0.035em;
      color: #ffffff;
      font-size: 20px;
      padding: 10px 25px;
      cursor: pointer;
      position: relative;

      &:focus {
        border: 1px solid #2fe212;
        outline: none;
      }

      @media (max-width: 870px) {
        width: 250px;
      }

      @media (max-width: 375px) {
        margin: 0px 5px;
        width: 65%;
      }
    }
  }

  &__input-text {
    margin-top: 30px;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__donate-btn {
    border: 1px solid #2df30d;
    border-radius: 40px 0px;
    width: 300px;
    height: 40px;
    background: none;
    color: #f8f1f1;
    text-align: center;
    letter-spacing: 0.035em;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 23px;
    display: block;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 100px;
    cursor: pointer;

    @media (max-width: 700px) {
      margin: 50px auto;
      width: 85%;
    }
  }
  &__donate-btn:hover {
    background: #16a800;
  }
}

.green-line {
  opacity: 0.2;
  border: 1px solid #2fe212;

  &-vertical {
    border-left: 1px solid #2fe212;
    height: 100vh;
  }
}

.input-text {
  color: #ffffff;
  letter-spacing: 0.035em;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 5px;

  @media (max-width: 375px) {
    width: 95%;
    margin: 0 auto;
  }
}

.buggy-amount {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.035em;
  color: #ffffff;
}

.text-area {
  width: 722px;
  background: rgb(0, 0, 0, 0.4);
  border-radius: 60px 0px;
  border: 1px solid #2fe212;
  color: #ffffff;
  letter-spacing: 0.035em;
  font-size: 20px;
  resize: none;
  padding: 25px;

  &:focus {
    border: 1px solid #2fe212;
    outline: none;
  }

  @media (max-width: 870px) {
    width: 85%;
    height: 265px;
  }
}

.amount-error {
  color: #dc143c;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}

.btn-counter {
  background-color: #2fe212;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #000;
  border: 4px solid rgb(9, 9, 9);

  @media (max-width: 870px) {
    width: 35px;
    height: 35px;
  }

  @media (max-width: 375px) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
  }
}

.btn-counter:hover {
  background-color: #16a800;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #000;

  @media (max-width: 870px) {
    width: 35px;
    height: 35px;
  }
}
.mint-page-headers {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.input-text-amount {
  display: flex;
  flex-direction: column;
}

.flex-d {
  display: flex;
  flex-direction: column;
}

.full-part {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.total-price {
  @media (max-width: 550px) {
    margin-top: 0.5rem;
  }
}
