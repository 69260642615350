.modal-wrapper {
  position: relative;
}

.modal-content {
  position: fixed;
  bottom: 50px;
  right: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 250px;
  height: 120px;
  background: #151515;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 4px 8px 4px #2fe212;
  z-index: 99;

  .cross-icon {
    position: absolute;
    opacity: 1;
    top: -15px;
    right: -15px;
    width: 35px;
    cursor: pointer;

    @media (max-width: 550px) {
      width: 30px;
      top: -10px;
      right: -10px;
    }
  }

  @media (max-width: 550px) {
    text-align: initial;
    width: 230px;
    bottom: 50px;
    right: 35px;
  }
}

.modal-icon {
  width: 35px;

  @media (max-width: 550px) {
    width: 30px;
  }
}
