@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&display=swap');

$fontFamily: 'Courier Prime', monospace;

$colorText: #f8f1f1;
$colorText2: #0f8514;

body {
  margin: 0;
  padding: 0;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-height: 100%;
}

#root {
  height: 100%;
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  max-width: 90rem;
  margin: 0 auto;
  padding: 0 4rem;

  @media (max-width: 400px) {
    padding: 0 2rem;
  }
}
