@import '../../index.scss';

span {
  color: white;
  text-align: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &__content {
    position: relative;
    width: 500px;
    height: 425px;
    border: 1px solid #2fe212;
    border-radius: 0px 60px;
    background-color: rgb(21, 21, 21, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 620px) {
      width: 100%;
      margin: 0 15px;
    }

    .cross-icon {
      position: absolute;
      opacity: 1;
      top: 0;
      right: 0;
      width: 35px;
      cursor: pointer;
    }

    &-buttons-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 65%;
      height: 55%;

      @media (max-width: 999px) {
        width: 80%;
      }

      @media (max-width: 620px) {
        width: 90%;
        height: 60%;
      }

      button {
        width: 100%;
        height: 50px;
        cursor: pointer;
        border: 1px solid #2fe212;
        border-radius: 15px;
        background: none;
        color: white;
        font-family: $fontFamily;
        text-transform: uppercase;
        font-size: 15px;

        &:hover {
          background-color: black;
        }

        @media (max-width: 620px) {
          min-height: 50px;
          height: unset;
        }
      }
    }
  }
}
