@import '../../index.scss';

* {
  font-family: $fontFamily;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  gap: 10px;
}

.statistic {
  &__wrapper {
    height: 100%;
  }

  &__go-back {
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 15px;
    cursor: pointer;
    margin-bottom: 1rem;

    * {
      color: #2df30d;
    }

    @media (max-width: 580px) {
      justify-content: unset;
      margin: 0 auto;
      margin-bottom: 1rem;
      gap: 1rem;
    }
  }

  &__nav {
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    margin-bottom: 80px;
    background: #151515;
    height: 60px;
    padding: 0 60px;

    @media (max-width: 1150px) {
      flex-direction: column;
      height: 150px;

      p {
        margin-bottom: -5px;
      }

      @media (max-width: 525px) {
        height: 180px;
        padding: 0 30px;
      }
    }

    &-center-button {
      font-size: 24px;
      line-height: 27px;
      letter-spacing: 0.035em;
      text-transform: uppercase;
      color: $colorText2;
      background-color: #151515;
      border: none;
      margin: 0 auto;

      @media (max-width: 1150px) {
        margin: 10px;
      }

      @media (max-width: 600px) {
        font-size: 20px;
      }
    }

    &-connect-wallet {
      width: 300px;
      height: 40px;
      background: #2df30d;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 40px 0px;
      color: black;
      text-transform: uppercase;
      cursor: pointer;
      border: none;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.035em;
      transition: all 0.15s ease-in-out;

      &:hover {
        background: #16a800;
      }

      @media (max-width: 600px) {
        height: 30px;
      }

      @media (max-width: 385px) {
        width: 270px;
      }
    }

    p {
      color: white;
    }
  }

  &__background-photo {
    background: no-repeat top/100% url('../../images/background.png');
    background-attachment: fixed;
    min-height: 100%;

    @media (max-width: 1350px) {
      background-size: 150%;
    }

    @media (max-width: 999px) {
      background-size: 250%;
    }

    @media (max-width: 580px) {
      background-size: 300%;
    }

    @media (max-width: 500px) {
      background-size: 450%;
    }

    @media (max-width: 400px) {
      background-size: 500%;
    }
  }

  &__dark-bg {
    background-color: rgba(0, 0, 0, 0.8);
    background-attachment: fixed;
    height: 100vh;
    min-height: 100%;

    @media (max-width: 1350px) {
      background-size: 150%;
      height: unset;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;

    .button {
      margin: 100px 0px 80px;
      background: none;
      color: $colorText;
      text-transform: uppercase;
      box-shadow: 0px 4px 8px 4px #2fe212;
      border-radius: 40px 0px;
      width: 60%;
      height: 38px;
      font-size: 20px;
      line-height: 23px;
      border: none;
      cursor: pointer;
      transition: all 0.15s ease-in-out;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__text-wrapper {
    background-color: rgba(9, 65, 0, 0.5);
  }

  &__text {
    color: #f8f1f1;
    font-size: 20px;
    line-height: 30px;
    padding: 30px 170px;
  }

  &__balances {
    width: 100%;

    &-text {
      color: #ffffff;
      font-size: 20px;
      padding: 20px 0;
    }

    &-bg {
      background: rgb(7, 7, 7, 0.5);
      width: 100%;
      margin-bottom: 30px;
      flex-direction: column;
      display: flex;
      justify-content: center;
    }

    &-container {
      width: 1280px;
      height: 102px;
      border: 1px solid rgb(47, 226, 18, 0.4);
      border-radius: 60px 0px;
      margin: auto;
      padding: 31px 0;
    }

    &-currency-amount {
      width: 100%;
      height: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgb(0, 0, 0, 0.4);

      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.035em;
        color: white;
      }
    }

    &-text-wrapper {
      display: flex;
      width: 50%;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__nfts {
    margin: 60px 0;

    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 30px;
    }
  }
}

.green-line {
  opacity: 0.2;
  border: 1px solid #2fe212;

  &-vertical {
    border-left: 1px solid #2fe212;
    height: 100vh;
  }
}

.statistic-content {
  display: flex;
  text-align: center;
  font-size: 14px;
  justify-content: space-around;
  padding: 10px;
  margin: 20px;
  color: #ffffff;
  padding-bottom: 190px;

  @media (max-width: 1350px) {
    flex-wrap: wrap;
    flex-direction: row;

    .statistic-block {
      align-items: center;
    }

    & > div {
      display: flex;
      flex-basis: calc(50% - 40px);
      justify-content: center;
      flex-direction: column;
    }

    & > div > div {
      display: flex;
      justify-content: center;
      flex-direction: row;
    }
  }

  @media (max-width: 770px) {
    padding-bottom: 50px;
  }
}

.statistic-header {
  display: flex;
  justify-content: center;
}

.statistic-title {
  color: #2df30d;
  text-align: center;
}

.statistic-cirle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  width: 280px;
  height: 280px;
  left: 150px;
  top: 258px;
  padding-right: 15px;
  padding-bottom: 15px;
  font-size: 26px;
  background-image: url('../../images//Vector.svg');
  background-size: 260px 260px;

  /* light green */

  @media (max-width: 470px) {
    padding: 0;
    background-size: 240px 240px;
    width: 240px;
    height: 240px;
  }
}
